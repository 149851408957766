import Menu from './Menu';

function Header() {

    return (
        <header className="bg-black z-50 top-0 left-0 w-full">
            <nav className="flex items-center max-w-screen-xl mx-auto px-6 py-3">
                <div className="flex flex-grow">
                    <a href="/"><img className="w-36 cursor-pointer" src="/assets/logo.png" alt="logo" /></a>
                </div>
                <div className="flex items-center justify-end space-x-6" style={{ fontFamily: 'Roboto' }}>
                    <a href="/"><p className="text-white">Simitçi Dünyası Meşrutiyet</p></a>
                </div>
                <div className="pl-4">
                    <Menu/>                    
                </div>
            </nav>
        </header>
    )
}

export default Header;