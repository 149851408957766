import axios from "axios";
import { useState, useEffect } from "react";
import { baseUrl } from "./env";
import { createBrowserHistory } from 'history';
import { Toaster, toast } from 'sonner'

export default function Login() {
    const history = createBrowserHistory();
    const [data, setData] = useState({
        username: '',
        password: '',
    })

    const handleChangeValue = (field, value) => {
        setData({ ...data, [field]: value });
    }

    const buttonDisabled = () => {
        let valid = true;
        if (!data?.username || !data?.password) {
            valid = false;
        }
        return valid;
    }

    const config = {
        headers: {
            'content-type': 'application/json',
        },
    };

    const handleLogin = () => {
        axios.post(`${baseUrl}/Auth/LoginUser`, data, config)
            .then(response => {
                if (response.data.authenticateResult) {
                    toast.success("Giriş başarılı!");
                    setTimeout(() => {
                        localStorage.setItem('authToken', response.data.authToken);
                        localStorage.setItem('authenticateResult', response.data.authenticateResult);
                        history.push('/authorized');
                        window.location.reload();
                    }, 2000);
                }
                else {
                    toast.error("Bir hata oluştu!");
                }

            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <div className="max-w-screen-md mx-auto">
            <div className="border border-gray-300 rounded-sm mt-10 ml-1 mr-1">
                <div className='flex flex-col gap-4 p-5'>
                    <div className='flex flex-col pt-4'>
                        <label className="text-left block mb-2 text-lg font-medium text-gray-900">Kullanıcı Adı :</label>
                        <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-lg focus:ring-black focus:border-black block w-full p-2.5" required
                            type="text"
                            id="name"
                            onChange={(e) => handleChangeValue("username", e.target.value)}
                        />
                        <label className="text-left block mb-2 text-lg font-medium text-gray-900 pt-8">Şifre :</label>
                        <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-lg focus:ring-black focus:border-black block w-full p-2.5" required
                            type="password"
                            id="password"
                            onChange={(e) => handleChangeValue("password", e.target.value)}
                        />
                    </div>
                    <div className='flex justify-end gap-2'>
                        <button disabled={!buttonDisabled()} onClick={handleLogin} className="mt-6 bg-green-700 hover:bg-gray-600 items-center justify-center bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Giriş Yap</button>
                    </div>
                </div>
            </div>
        </div>
    )
}