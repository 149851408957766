import { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../../env';

export default function AdminCreateProduct() {
    const token = localStorage.getItem("authToken");
    const [categories, setCategories] = useState([]);
    const [data, setData] = useState({
        name: '',
        description: '-',
        price: '',
        isOnStock: 1,
        categoryId: '',
        img: '',
    })

    const buttonDisabled = () => {
        let valid = true;
        if (!data?.name || !data?.img) {
            valid = false;
        }
        return valid;
    }

    const handleChangeValue = (field, value) => {
        setData({ ...data, [field]: value });
    }

    const handleSubmit = () => {
        let formData = new FormData();
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('price', data.price);
        formData.append('isOnStock', data.isOnStock);
        formData.append('categoryId', data.categoryId);
        formData.append('img', data.img);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            },
        };
        axios.post(`${baseUrl}/Product/createProduct`, formData, config)
            .then(response => {
                console.log(response);
                window.location.reload();
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        axios.get(`${baseUrl}/Category/getAllCategories`)
          .then(response => {
            setCategories(response.data);
            setData({ ...data, categoryId: response.data[0].id });
          })
          .catch(error => {
            console.log(error);
          });
      }, []);

    return (
        <div className="max-w-[600px] mx-auto border border-gray-300 mt-10">
            <div className='flex flex-col gap-4 p-10'>
                <div className="flex flex-col pt-4">
                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Kategori</label>
                    <select className='border border-black' value={data?.categoryId} onChange={(e) => handleChangeValue("categoryId", e.target.value)} >
                        {categories?.map(category =>
                            <option key={category.id} value={category.id}>{category.name}</option>
                        )}
                    </select>
                </div>
                <div className='flex flex-col pt-4'>
                    <label className="block mb-2 text-sm font-medium text-gray-900">Ürün Adı</label>
                    <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5" required
                        type="text"
                        id="name"
                        onChange={(e) => handleChangeValue("name", e.target.value)}
                    />
                </div>
                <div className='flex flex-col pt-4'>
                    <label className="block mb-2 text-sm font-medium text-gray-900">Açıklama</label>
                    <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5" required
                        type="text"
                        value={data?.description}
                        id="description"
                        onChange={(e) => handleChangeValue("description", e.target.value)}
                    />
                </div>
                <div className='flex flex-col pt-4'>
                    <label className="block mb-2 text-sm font-medium text-gray-900">Fiyat</label>
                    <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5" required
                        type="text"
                        id="price"
                        onChange={(e) => handleChangeValue("price", e.target.value)}
                    />
                </div>
                <div className='flex flex-col pt-4'>
                    <label className="block mb-2 text-sm font-medium text-gray-900">Stok Durumu</label>
                    <select className='border border-black' value={data?.isOnStock} onChange={(e) => handleChangeValue("isOnStock", e.target.value)} >
                        <option value={1}>Var</option>
                        <option value={0}>Yok</option>
                    </select>
                </div>
                <div className='flex flex-col pt-4'>
                    <label className="block mb-2 text-sm font-medium text-gray-900 ">Ürün Fotoğrafı</label>
                    <input
                        onChange={(e) => handleChangeValue("img", e.target.files[0])}
                        type="file" id="file" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5" required />
                </div>
                <div className='flex justify-end gap-2'>
                    <button disabled={!buttonDisabled()} onClick={handleSubmit} className="mt-6 bg-gray-900 hover:bg-gray-600 items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Ekle</button>
                </div>
            </div>
        </div>
    )
}