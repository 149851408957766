import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { baseUrl } from '../../env.js'
import AdminEditCategory from './AdminEditCategory.js';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function BasicTable() {
  const token = localStorage.getItem("authToken");
  const [categories, setCategories] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [toBeEdited, setToBeEdited] = useState();
  const [editCategoryOpen, setEditCategoryOpen] = useState(false);

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const editCategory = (category) => {
    setToBeEdited(category);
    setEditCategoryOpen(true);
  }

  const deleteCategory = (id) => {
    setToBeDeleted(id);
    setDeleteOpen(true);
  }

  const handleDelete = () => {
    axios.delete(`${baseUrl}/Category/deleteCategory?categoryId=${toBeDeleted}`, config)
      .then(response => {
        setToBeDeleted(null);
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {
    axios.get(`${baseUrl}/Category/getAllCategories`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <div className='px-10 pt-10'>
      <Dialog open={deleteOpen && toBeDeleted} onClose={() => setToBeDeleted(null)}>
        <DialogTitle>Bu kategoriyi silmek istediğinize emin misiniz?</DialogTitle>
        <DialogActions>
          <button onClick={handleDelete} className="mt-6 bg-green-700 hover:bg-gray-600 items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Evet</button>
          <button onClick={() => setToBeDeleted(null)} className="mt-6 bg-gray-900 hover:bg-gray-600 items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Hayır</button>
        </DialogActions>
      </Dialog>
      {editCategoryOpen &&
        <AdminEditCategory
          editCategoryOpen={editCategoryOpen}
          setEditCategoryOpen={setEditCategoryOpen}
          toBeEdited={toBeEdited} />}
      <div className='mx-auto'>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Sıralama</StyledTableCell>
                <StyledTableCell>Kategori Adı</StyledTableCell>
                <StyledTableCell>Düzenle</StyledTableCell>
                <StyledTableCell>Sil</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories?.map((category) => (
                <StyledTableRow key={category.id}>
                  <StyledTableCell>{category.arrangement}</StyledTableCell>
                  <StyledTableCell>{category.name}</StyledTableCell>
                  <StyledTableCell><Button color='warning' variant='contained' onClick={() => editCategory(category)}>Düzenle</Button></StyledTableCell>
                  <StyledTableCell><Button color='error' variant='contained' onClick={() => deleteCategory(category.id)}>Sil</Button></StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
