import { createBrowserHistory } from 'history';

export default function AdminHome() {
    const history = createBrowserHistory();
    const handleLogout = () => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("authenticateResult");
        history.push('/');
        window.location.reload();
    }
    return (
        <div className="min-h-screen">
            <div className="bg-white mx-auto max-w-2xl overflow-hidden border border-solid border-slate-200 shadow-2xl rounded-lg m-8 p-8">
                Admin
            </div>            
        </div>
    )
}