import AdminSidebar from "./AdminSidebar";
import { Outlet } from "react-router-dom";
import { createBrowserHistory } from 'history';

export default function Admin() {
    const history = createBrowserHistory();
    const handleLogout = () => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("authenticateResult");
        history.push('/');
        window.location.reload();
    }
    return (
        <>
            <div className="not-sr-only md:sr-only bg-white mx-auto max-w-2xl overflow-hidden border border-solid border-gray-300 shadow-2xl rounded-sm m-8 p-8">
                <div className="grid grid-cols-2">
                    <a href="/authorized/categories" className="">
                        <span className="flex-1 whitespace-nowrap text-sm text-blue-800 font-medium">Kategoriler</span>
                    </a>
                    <a href="/authorized/create-category" className="">
                        <span className="flex-1 whitespace-nowrap text-sm text-blue-800 font-medium">Kategori Ekle</span>
                    </a>
                </div>
                <div className="grid grid-cols-2 pt-2">
                    <a href="/authorized/products" className="">
                        <span className="flex-1 whitespace-nowrap text-sm text-blue-800 font-medium">Ürünler</span>
                    </a>
                    <a href="/authorized/create-product" className="">
                        <span className="flex-1 whitespace-nowrap text-sm text-blue-800 font-medium">Ürün Ekle</span>
                    </a>
                </div>
                <div className="grid grid-cols-1 pt-2">
                    <a className="">
                        <span onClick={handleLogout} className="flex-1 whitespace-nowrap text-sm text-red-500 font-medium">Çıkış Yap</span>
                    </a>
                </div>
            </div>
            <div className=""><AdminSidebar /></div>
            <div>
                <div className="sm:ps-[300px]"><Outlet /></div>
            </div>
        </>
    )
}