import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from '../../env';


function AdminEditCategory({ editCategoryOpen, setEditCategoryOpen, toBeEdited }) {
    const token = localStorage.getItem("authToken");
    const [open, setOpen] = useState(editCategoryOpen);
    const [data, setData] = useState({
        name: toBeEdited.name,
        arrangement: toBeEdited.arrangement
    })

    const handleClose = () => {
        setEditCategoryOpen(false);
    }

    const handleChangeValue = (field, value) => {
        setData({ ...data, [field]: value });
    }

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const handleSubmit = () => {
        axios.put(`${baseUrl}/Category/updateCategory?categoryId=${toBeEdited.id}`, data, config)
            .then(response => {
                console.log(response.data);
                setEditCategoryOpen(false);
                window.location.reload();
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Kategori Düzenle</DialogTitle>
            <DialogContent>
                <div className="grid grid-cols-1 gap-4">
                    <div className='flex flex-col md:flex-row gap-4'>
                        <div className="">
                            <label for="name" className="block mb-2 text-sm font-medium text-gray-900">Sıralama</label>
                            <input
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-black focus:border-black block w-full p-2.5" required
                                type="number"
                                id="arrangement"
                                value={data?.arrangement}
                                onChange={(e) => handleChangeValue("arrangement", e.target.value)} />
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row gap-4'>
                        <div className="">
                            <label for="name" className="block mb-2 text-sm font-medium text-gray-900">Kategori Adı</label>
                            <input
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-black focus:border-black block w-full p-2.5" required
                                type="text"
                                id="name"
                                value={data?.name}
                                onChange={(e) => handleChangeValue("name", e.target.value)} />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <div className='flex justify-end gap-2'>
                    <button onClick={handleSubmit} className="mt-6 bg-green-700 hover:bg-gray-600 items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Onayla</button>
                    <button onClick={handleClose} className="mt-6 bg-gray-900 hover:bg-gray-600 items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Kapat</button>
                </div>
            </DialogActions>
        </Dialog>
    );
}

export default AdminEditCategory;