import { createBrowserHistory } from 'history';

function AdminSidebar() {
    const history = createBrowserHistory();
    const handleLogout = () => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("authenticateResult");
        history.push('/');
        window.location.reload();
    }

    return (
        <aside id="default-sidebar" className="fixed top-0 left-0 z-30 w-64 h-screen transition-transform -translate-x-full md:translate-x-0" aria-label="Sidebar">
            <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800 shadow-2xl">
                <ul className="space-y-2 font-medium">
                    <li>
                        <a href="/authorized" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">                            
                            <span className="flex-1 ms-3 whitespace-nowrap text-sm">Home</span>
                        </a>
                    </li>
                    <li>
                        <a href="/authorized/categories" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            
                            <span className="flex-1 ms-3 whitespace-nowrap text-sm">Kategoriler</span>
                        </a>
                    </li>
                    <li>
                        <a href="/authorized/create-category" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            
                            <span className="flex-1 ms-3 whitespace-nowrap text-sm">Kategori Ekle</span>
                        </a>
                    </li>
                    <li>
                        <a href="/authorized/products" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            
                            <span className="flex-1 ms-3 whitespace-nowrap text-sm">Ürünler</span>
                        </a>
                    </li>    
                    <li>
                        <a href="/authorized/create-product" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                            
                            <span className="flex-1 ms-3 whitespace-nowrap text-sm">Ürün Ekle</span>
                        </a>
                    </li>                    
                    <li>
                        <a className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">                           
                            <span onClick={handleLogout} className="flex-1 ms-3 whitespace-nowrap text-sm">Çıkış Yap</span>
                        </a>
                    </li>
                </ul>
            </div>
        </aside>
    );
}

export default AdminSidebar;