import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { baseUrl } from '../../env.js'
import AdminEditProduct from './AdminEditProduct.js'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function BasicTable() {
  const token = localStorage.getItem("authToken");
  const [products, setProducts] = useState();
  const [categories, setCategories] = useState();
  const [filteredProducts, setFilteredProducts] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [toBeEdited, setToBeEdited] = useState();
  const [editProductOpen, setEditProductOpen] = useState(false);

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const editProduct = (product) => {
    setToBeEdited(product);
    setEditProductOpen(true);
  }

  const deleteProduct = (id) => {
    setToBeDeleted(id);
    setDeleteOpen(true);
  }

  const handleDelete = () => {
    axios.delete(`${baseUrl}/Product/deleteProduct?productId=${toBeDeleted}`, config)
      .then(response => {
        setToBeDeleted(null);
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {
    axios.get(`${baseUrl}/Product/getAllProducts`, config)
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios.get(`${baseUrl}/Category/getAllCategories`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const filterByCategory = (categoryName) => {
    if (categoryName == "all") {
      setFilteredProducts();
    }
    else {
      axios.get(`${baseUrl}/Category/getCategoryByName?categoryName=${categoryName}`)
        .then(response => {
          setFilteredProducts(response.data.products);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  return (
    <div className='px-10 pt-10'>
      <Dialog open={deleteOpen && toBeDeleted} onClose={() => setToBeDeleted(null)}>
        <DialogTitle>Bu ürünü silmek istediğinize emin misiniz?</DialogTitle>
        <DialogActions>
          <button onClick={handleDelete} className="mt-6 bg-green-700 hover:bg-gray-600 items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Evet</button>
          <button onClick={() => setToBeDeleted(null)} className="mt-6 bg-gray-900 hover:bg-gray-600 items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Hayır</button>
        </DialogActions>
      </Dialog>
      {editProductOpen &&
        <AdminEditProduct
          editProductOpen={editProductOpen}
          setEditProductOpen={setEditProductOpen}
          toBeEdited={toBeEdited} />}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Ürün Adı</StyledTableCell>
              <StyledTableCell>Kategori
                <select className='border border-black text-black md:ml-6' onChange={(e) => filterByCategory(e.target.value)} >
                  <option value="all">Hepsi</option>
                  {categories?.map(category =>
                    <option key={category.id} value={category.name}>{category.name}</option>
                  )}
                </select>
                &nbsp;&nbsp;
                {filteredProducts?.length > 0 ?
                  <>{filteredProducts?.length} Adet Ürün Mevcut</>
                  :
                  <>{products?.length} Adet Ürün Mevcut</>
                }
              </StyledTableCell>
              <StyledTableCell>Açıklama</StyledTableCell>
              <StyledTableCell>Fiyat</StyledTableCell>
              <StyledTableCell>Stok Durumu</StyledTableCell>
              <StyledTableCell>Düzenle</StyledTableCell>
              <StyledTableCell>Sil</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProducts?.length > 0 ?
              <>{filteredProducts?.map((product) => (
                <StyledTableRow key={product.id}>
                  <StyledTableCell>{product.name}</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>{product.description}</StyledTableCell>
                  <StyledTableCell>{product.price}</StyledTableCell>
                  <StyledTableCell>
                    {product.isOnStock ?
                      <svg xmlns="http://www.w3.org/2000/svg" color='green' fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                      </svg>
                      :
                      <svg xmlns="http://www.w3.org/2000/svg" color="red" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                      </svg>
                    }
                  </StyledTableCell>
                  <StyledTableCell><Button color='warning' variant='contained' onClick={() => editProduct(product)}>Düzenle</Button></StyledTableCell>
                  <StyledTableCell><Button color='error' variant='contained' onClick={() => deleteProduct(product.id)}>Sil</Button></StyledTableCell>
                </StyledTableRow>
              ))}</>
              :
              <>
                {products?.map((product) => (
                  <StyledTableRow key={product.id}>
                    <StyledTableCell>{product.name}</StyledTableCell>
                    <StyledTableCell>{product.category.name}</StyledTableCell>
                    <StyledTableCell>{product.description}</StyledTableCell>
                    <StyledTableCell>{product.price}</StyledTableCell>
                    <StyledTableCell>
                      {product.isOnStock ?
                        <svg xmlns="http://www.w3.org/2000/svg" color='green' fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" color="red" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                      }
                    </StyledTableCell>
                    <StyledTableCell><Button color='warning' variant='contained' onClick={() => editProduct(product)}>Düzenle</Button></StyledTableCell>
                    <StyledTableCell><Button color='error' variant='contained' onClick={() => deleteProduct(product.id)}>Sil</Button></StyledTableCell>
                  </StyledTableRow>
                ))}
              </>}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
