import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { createBrowserHistory } from 'history';

function SearchBar() {
    const history = createBrowserHistory();
    const [products, setProducts] = useState();
    const [searchData, setSearchData] = useState();

    const handleChangeValue = (value) => {
        setSearchData(value);
    }

    const searchProduct = (e) => {
        e.preventDefault();
        history.push(`/search/${searchData}`)
        window.location.reload();
    }

    const buttonDisabled = () => {
        let valid = true;
        if (!(searchData?.length > 2)) {
            valid = false;
        }
        return valid;
    }

    return (
        <div className="flex flex-row max-w-screen-md mx-auto">
            <div className="my-auto pt-2 pl-1">
                <Link to='/'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8">
                        <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                        <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
                    </svg>

                </Link>
            </div>
            <form className="max-w-screen-xl w-full mx-auto pt-2 px-1">
                <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input type="search" onChange={(e) => handleChangeValue(e.target.value)} className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-sm bg-gray-50" placeholder="Börek, Tost... (En az 3 harf girmelisiniz.)" />
                    <button
                        disabled={!buttonDisabled()}
                        onClick={(e) => searchProduct(e)}
                        className="text-white absolute end-2.5 bottom-2.5 bg-red-700 focus:outline-none font-medium rounded-lg text-sm px-4 py-2">Ara</button>
                </div>
            </form>
        </div>
    )
}

export default SearchBar;