import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from '../../env';


function AdminEditCategory({ editProductOpen, setEditProductOpen, toBeEdited }) {
    const token = localStorage.getItem("authToken");
    const [open, setOpen] = useState(editProductOpen);
    const [categories, setCategories] = useState();
    const [data, setData] = useState({
        name: toBeEdited?.name,
        description: toBeEdited?.description,
        price: toBeEdited?.price,
        isOnStock: toBeEdited?.isOnStock,
        categoryId: toBeEdited?.categoryId
    })

    const handleClose = () => {
        setEditProductOpen(false);
    }

    const handleChangeValue = (field, value) => {
        setData({ ...data, [field]: value });
    }

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const handleSubmit = () => {
        axios.put(`${baseUrl}/Product/updateProduct?productId=${toBeEdited.id}`, data, config)
            .then(response => {
                
                console.log(response.data);
                setEditProductOpen(false);
                window.location.reload();
            })
            .catch(error => {

                console.log(error);
            });
    }

    useEffect(() => {
        axios.get(`${baseUrl}/Category/getAllCategories`)
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Ürün Düzenle</DialogTitle>
            <DialogContent>

                <div className="flex flex-col pt-4">
                    <label for="category" className="block mb-2 text-sm font-medium text-gray-900 ">Kategori</label>
                    <select className='border border-black' value={data?.categoryId} onChange={(e) => handleChangeValue("categoryId", e.target.value)} >
                        {categories?.map(category =>
                            <option value={category.id}>{category.name}</option>
                        )}
                    </select>
                </div>
                <div className='flex flex-col pt-4'>
                    <label for="name" className="block mb-2 text-sm font-medium text-gray-900">Ürün Adı</label>
                    <input
                        value={data?.name}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5" required
                        type="text"
                        id="name"
                        onChange={(e) => handleChangeValue("name", e.target.value)}
                    />
                </div>
                <div className='flex flex-col pt-4'>
                    <label for="description" className="block mb-2 text-sm font-medium text-gray-900">Açıklama</label>
                    <input
                        value={data?.description}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5" required
                        type="text"
                        id="description"
                        onChange={(e) => handleChangeValue("description", e.target.value)}
                    />
                </div>
                <div className='flex flex-col pt-4'>
                    <label for="price" className="block mb-2 text-sm font-medium text-gray-900">Fiyat</label>
                    <input
                        value={data?.price}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5" required
                        type="text"
                        id="price"
                        onChange={(e) => handleChangeValue("price", e.target.value)}
                    />
                </div>
                <div className='flex flex-col pt-4'>
                    <label for="name" className="block mb-2 text-sm font-medium text-gray-900">Stok Durumu</label>
                    <select className='border border-black' value={data?.isOnStock} onChange={(e) => handleChangeValue("isOnStock", e.target.value)} >
                        <option value={1}>Var</option>
                        <option value={0}>Yok</option>
                    </select>
                </div>

            </DialogContent>
            <DialogActions>
                <div className='flex justify-end gap-2'>
                    <button onClick={handleSubmit} className="mt-6 bg-green-700 hover:bg-gray-600 items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Onayla</button>
                    <button onClick={handleClose} className="mt-6 bg-gray-900 hover:bg-gray-600 items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Kapat</button>
                </div>
            </DialogActions>
        </Dialog>
    );
}

export default AdminEditCategory;