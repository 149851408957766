import { Link } from "react-router-dom";
import { baseUrl } from "../env.js";
import axios from "axios";
import { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";

function Categories() {
    const [categories, setCategories] = useState();

    useEffect(() => {
        axios.get(`${baseUrl}/Category/getAllCategories`)
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, [])


    if (categories) {
        return (
            <section className="max-w-screen-md mx-auto" style={{ fontFamily: 'Roboto' }}>
                <div className="grid grid-cols-2 gap-1 pt-2">
                    {categories?.map((category, index) => (
                        <Link key={index} to={{ pathname: `/${category.name}` }}>
                            <div key={index} className="bg-white border border-gray-200 p-1 rounded-lg relative min-h-[300px] max-h-[400px]">
                                <img className="h-[180px] w-full mx-auto " src={`${baseUrl}/${category?.img}`}/>
                                <div className="flex flex-col items-center pt-3">
                                    <h1 className="text-gray-900 text-2xl font-medium">{category.name}</h1>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </section>
        )
    }

    else {
        return (
            <div className="flex h-[70vh]">
                <div className="m-auto">
                    <ClipLoader className='' />
                </div>
            </div>
        )
    }
}

export default Categories;